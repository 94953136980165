<template>
<div id="app">
    <div class="space"></div>
    <NavBarComponent></NavBarComponent>
    <button type="button" style="z-index: 1;" class="btn btn-danger rounded-circle shadow fs-2 bt-fixfolio" data-bs-toggle="modal" data-bs-target="#modalFolio">
        <i class="bi bi-ticket-detailed"></i>
    </button>
    <button type="button" @click="openWhats()" style="z-index: 1; " target="_BLANK" class="btn btn-success rounded-circle shadow fs-2 bt-fixwhats align-bottom"><i class="bi bi-whatsapp"></i></button>
    <!-- Button trigger modal -->
    <button type="button" style="z-index: 1; " class="btn btn-info rounded-circle shadow fs-2 bt-fixcred text-light" data-bs-toggle="modal" data-bs-target="#modalCard">
        <i class="bi bi-credit-card"></i>
    </button>

    <!-- Modal 1 -->
    <div class="modal fade" id="modalCard" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Referencias Bancarias</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row justify-content-center">
                        <div class="col-lg-10 col-md-10 col-sm-10 mb-4">
                            <div class="card bg-light h-100">
                                <div class="card-header bg-primary w-100 text-light" style="height:2rem">BBVA</div>
                                <div class="card-body">
                                    <div class="card-text fs-2">
                                        4152 3140 7510 2858 <button v-on:click="copyr1" class="btn btn-success" type="button"><i class="bi bi-clipboard2-fill"></i>copiar</button>
                                    </div>
                                </div>
                                <div class="card-footer bg-info  w-100">Titular: ABRAHAM LOYA GARCIA</div>
                            </div>
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-10 mb-4">
                            <div class="card bg-light h-100">
                                <div class="card-header bg-primary w-100 text-light" style="height:2rem">BBVA</div>
                                <div class="card-body">
                                    <div class="card-text fs-2">
                                        4152 3141 1879 9348 <button v-on:click="copyr2" class="btn btn-success" type="button"><i class="bi bi-clipboard2-fill"></i>copiar</button>
                                    </div>
                                </div>
                                <div class="card-footer bg-info  w-100">Titular: LUIS EDUARDO OLIVAS PEREZ</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal 2 -->
    <div class="modal fade" id="modalFolio" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Busqueda de Folios</h1>
                    <button @click="clearWindowFolio()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="input-group mb-3">
                        <input type="number" class="form-control" placeholder="Numero de Telefono" aria-label="Ingresa tu numero de Telefono:" aria-describedby="button-addon2" v-model="phoneNumber">
                        <button @click="searchPhone()" class="btn btn-primary" type="button" id="button-addon2"><i class="bi bi-search"></i></button>
                    </div>
                    <div class="card border-warning mx-2 my-5" v-for="folio in folioFilter" :key="folio">
                        <div class="card-header bg-warning w-100">Folio:{{folio}} <button @click="copy(folio)" type="button" class="btn btn-success mx-2 shadow"><i class="bi bi-clipboard2-fill"></i></button></div>
                        <div class="card-body justify-content-center">
                            <h5 class="card-title">{{returnParticipantN(folio)}}</h5>
                            <p class="card-text">Rifa: {{returnRifaName(folio)}}</p>
                            <p class="card-text">Estado del folio: {{wState(returnPayed(folio))}}</p>
                            <p class="card-text fs-5 w-50 badge bg-success">Total: ${{returnTotal(folio)}}</p>
                            <p class="card-text">Lista de Boletos Seleccionados:</p>
                            <div class="card-text badge bg-warning m-1" v-for="number in returnTicketList(folio)" :key="number">{{number.toString().padStart(5, '0')}}</div>
                            <br>
                            <router-link :to="`/tickets/search/${folio}`" type="button" class="btn btn-info text-light rounded-pill m-2 w-75"><i class="bi bi-eye"></i> Ver</router-link>
                        </div>
                        <div class="card-footer w-100">{{returnDate(folio)}}</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="clearWindowFolio()" type="button" class="btn btn-danger" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <router-view />
</div>
</template>

<script>
import NavBarComponent from './components/NavBarComponent'
import axios from 'axios'

export default {
    name: 'App',
    data() {
        return {
            url: 'https://rifasculichitown.com/api/',
            data: [],
            raffleData: [],
            folioFilter: [],
            phoneNumber: '',
        }
    },
    async created() {
        axios.get(`${this.url}tickets`)
            .then((response) => this.data = response.data)
            .catch((error) => alert(error));

        axios.get(`${this.url}raffles`)
            .then((response) => this.raffleData = response.data)
            .catch((error) => alert(error));

        console.log(this.raffleData);


    },
    components: {
        NavBarComponent
    },
    methods: {

        searchPhone() {
            const folioFi = this.data.tickets.filter(item => item.phone_number == this.phoneNumber);
            const folioUnico = new Set();
            folioFi.forEach(item => folioUnico.add(item.code));
            this.folioFilter = Array.from(folioUnico);
        },
        returnTotal(folio) {
            const folioFi = this.data.tickets.filter(item => item.code === folio);
            const total = folioFi.length * parseInt(this.returnRifaCosto(folio));
            return total;
        },
        returnRifa(folio) {
            const folioFi = this.data.tickets.filter(item => item.code === folio);
            return folioFi[0].raffle_id;
        },
        returnRifaName(folio) {
            const x = this.raffleData.raffles.find(raffle => raffle.id === parseInt(this.returnRifa(folio)));
            console.log(x.name);
            return x.name;

        },
        returnRifaCosto(folio) {
            const x = this.raffleData.raffles.find(raffle => raffle.id === parseInt(this.returnRifa(folio)));
            console.log(x.ticket_cost);
            return x.ticket_cost;

        },
        returnParticipantN(folio) {
            const folioFi = this.data.tickets.filter(item => item.code === folio);
            return folioFi[0].participant_name;
        },
        returnPhoneNumber(folio) {
            const folioFi = this.data.tickets.filter(item => item.code === folio);
            return folioFi[0].phone_number;
        },
        returnPayed(folio) {
            const folioFi = this.data.tickets.filter(item => item.code === folio);
            return folioFi[0].payed;
        },
        returnTicketList(folio) {
            const numbers = this.data.tickets.filter(item => item.code === folio);
            const filter = numbers.flatMap(item => item.number);
            return filter;
        },
        returnDate(folio) {
            const date = this.data.tickets.filter(item => item.code === folio);
            const opcionesDeFormato = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
            };
            const fechaHora = new Date(date[0].created_at);
            const fechaHoraFormateada = fechaHora.toLocaleString("es-ES", opcionesDeFormato);
            return fechaHoraFormateada;
        },
        wState(payed) {
            if (payed == 1) {
                return "Pagado y Participando"
            } else {
                return "Apartado en espera de pago"
            }
        },
        clearWindowFolio() {
            this.phoneNumber = null;
            this.searchPhone()
        },
        copyr1() {
            const textToCopy = "4152 3140 7510 2858";

            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    alert("¡Copiaste la cuenta 4152 3140 7510 2858!");
                })
                .catch((err) => {
                    console.error('Error al copiar al portapapeles:', err);
                });
        },
        copyr2() {
            const textToCopy = "4152 3141 1879 9348";

            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    alert("¡Copiaste la cuenta 4152 3141 1879 9348!");
                })
                .catch((err) => {
                    console.error('Error al copiar al portapapeles:', err);
                });
        },
        copy(value) {
            navigator.clipboard.writeText(value)
                .then(() => {
                    alert("¡Copiaste al portapapeles " + value);
                })
                .catch((err) => {
                    console.error('Error al copiar al portapapeles:', err);
                });
        },
        openWhats() {
            window.open("https://wa.me/526672101015", "_BLANK")
        }
    }
}
</script>

<style>
#app {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: black;
}

.space {
    height: 10vh;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}

.shadow {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
}

.borderRounded {
    border-radius: 30px;
}

.card {

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.4);

    flex-direction: column;

}

.bt-fixfolio {
    z-index: 2;
    position: fixed;
    top: 70%;
    left: 3%;
    width: 4rem;
    height: 4rem;
}

.bt-fixwhats {
    z-index: 2;
    position: fixed;
    top: 80%;
    left: 3%;
    width: 4rem;
    height: 4rem;
}

.bt-fixcred {
    z-index: 2;
    position: fixed;
    top: 90%;
    left: 3%;
    width: 4rem;
    height: 4rem;
}

@media only screen and (max-width: 1000px) {
    .space {
        height: 45vh;
    }

    .bt-fixfolio {
        z-index: 2;
        position: fixed;
        top: 70%;
        left: 2%;
        width: 4rem;
        height: 4rem;
    }

    .bt-fixwhats {
        z-index: 2;
        position: fixed;
        top: 80%;
        left: 2%;
        width: 4rem;
        height: 4rem;
    }

    .bt-fixcred {
        z-index: 2;
        position: fixed;
        top: 90%;
        left: 2%;
        width: 4rem;
        height: 4rem;
    }
}
</style>
