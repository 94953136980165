<template>
    <nav class="bg-gradient">
        <router-link to="/rifas" class="logoLink"><img src="../assets/logo.png" class="logoBar" alt="Logo"></router-link>
        <router-link to="/rifas" class="a"><i class="fa-solid fa-ticket"></i><span class="space"></span>Rifas</router-link>
        <router-link to="/testimonios" class="a"><i class="fa-solid fa-photo-film"></i><span class="space"></span>Testimonios</router-link>
        <router-link to="/nosotros" class="a"><i class="fa-solid fa-building"></i><span class="space"></span>Nosotros</router-link>
        <router-link to="/instrucciones" class="a"><i class="bi bi-list-ol"></i><span class="space"></span>Instrucciones</router-link>
    </nav>
</template>

<script>

    export default {
        name: 'NavBarComponent',
    }

</script>

<style>

    nav {
        background-color: BLACK;
        color: #fae68a;


        z-index: 4;
        position: absolute;
        top: 0;
        width: 100vw;
        height: 10vh;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    nav .a, .logoLink {
        text-decoration: none;
        color: #fae68a;
        font-size: 1.3rem;

        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 17%;

        transition: 0.3s ease-in-out;
    }

    nav .a:hover {
        background-color: var(--primary-color);
        color: BLACK;
        font-size: 1.5rem;
        transform: scale(1.05);
    }

    .logoBar {
        margin-top: 9vh;
        height: 25vh;
        transition: 0.3s ease-in-out;
    }

    .logoBar:hover {
        filter: brightness(200%);
        transform: scale(1.4) rotate(-10deg);
    }

    .space {
        width: 10px;
    }
    
    @media only screen and (max-width: 1000px) {
        nav {
            background-color: BLACK;
            color: #fae68a;

            z-index: 1;
            position: absolute;
            top: 0;
            width: 100vw;
            height: 45vh;

            display: flex;
            justify-content: center;
            align-items: center;

            flex-wrap: wrap;
        }

        nav .a, .logoLink {
            text-decoration: none;
            color: #fae68a;
            font-size: 1.3rem;

            display: flex;
            justify-content: center;
            align-items: center;

            height: 5vh;
            width: 300px;

            transition: 0.3s ease-in-out;
        }

        .logoBar {
            height: 100%;
            margin-top: 0;
        }
        
        .logoLink {
            height: 15vh;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
    }

</style>