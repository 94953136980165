import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/admin',
    name: 'admin',
    component: () => import ('../views/AdminView.vue')
  }
  ,
  {
    path: '/',
    name: 'raffles',
    component: () => import ('../views/RafflesView.vue')
  },
  {
    path: '/rifas',
    name: 'raffles',
    component: () => import ('../views/RafflesView.vue')
  },
  {
    path: '/nosotros',
    name: 'we',
    component: () => import ('../views/WeView.vue')
  },
  {
    path: '/testimonios',
    name: 'testimonials',
    component: () => import ('../views/TestimonialsView.vue')
  },
  {
    path: '/instrucciones',
    name: 'instructions',
    component: () => import ('../views/InstructionsView.vue')
  },
  {
    path: '/:id',
    name: 'tickets',
    component: () => import ('../views/TicketsView.vue')
  },
  {
    path: '/tickets/search/:id',
    name: 'virtual_ticket',
    component: () => import ('../views/VirtualTicketView.vue')
  },
  {
    path: '/control_pane',
    name: 'control_pane',
    component: () => import ('../views/ControlPane.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
